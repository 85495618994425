import { useContext, useState } from 'react';
import Circle from '../../components/common/Circle/Circle';
import TwoColumnLayout from '../../components/layout/TwoColumnLayout/TwoColumnLayout';
import { FallContext } from '../../contexts/FallContext';
import IncidentCard from '../../components/common/IncidentCard/IncidentCard';
import useMediaQuery from '../../hooks/useMediaQuery';
import './Dashboard.scss';

function Dashboard() {
    const { isMobileDevice } = useMediaQuery();
    const { getFallsToReview, getFallsToConfirm, fallsToActionCount } = useContext(FallContext);

    const [showReviewData, setShowReviewData] = useState(true);
    const isOnReviewTab = () => showReviewData;
    const isOnConfirmTab = () => !showReviewData;
    const showReviewTab = () => setShowReviewData(true);
    const showConfirmTab = () => setShowReviewData(false);

    function renderQueueCards(fallList: FallList) {
        const queueItems = Object.values(fallList);
        return queueItems.map((fall, index) => {
            const isFirstItem = index === 0;
            const isLastItem = index + 1 === queueItems.length;
            return (
                <div
                    key={fall.id}
                    className={`dashboard__queue-container__card p-3
                    ${isFirstItem && 'rounded-top'} 
                    ${isLastItem && 'rounded-bottom'}`}
                >
                    <IncidentCard fall={fall} />
                </div>
            );
        });
    }

    function renderDashboardTabLayout() {
        return (
            <div className="dashboard">
                <div className="dashboard__tabs row h-100 m-0 mb-2">
                    <button
                        type="button"
                        className={`col-6 px-0 py-2 ${!isOnReviewTab() && 'non-active'}`}
                        onClick={showReviewTab}
                    >
                        <span className={`dashboard__tabs__header ${isOnReviewTab() && 'active'}`}>
                            Require Review
                            <Circle
                                className="bg-danger ml-2"
                                text={fallsToActionCount.review}
                                diameter="1.7rem"
                            />
                        </span>
                    </button>
                    <button
                        type="button"
                        className={`col-6 p-0 py-2 ${!isOnConfirmTab() && 'non-active'}`}
                        onClick={showConfirmTab}
                    >
                        <span className={`dashboard__tabs__header ${isOnConfirmTab() && 'active'}`}>
                            Confirm Review
                            <Circle
                                className="bg-danger ml-2"
                                text={fallsToActionCount.confirm}
                                diameter="1.7rem"
                            />
                        </span>
                    </button>
                </div>
                <div className="dashboard__queue-container p-2">
                    {renderQueueCards(isOnReviewTab() ? getFallsToReview() : getFallsToConfirm())}
                </div>
            </div>
        );
    }

    function renderDashboardColumnLayout() {
        return (
            <TwoColumnLayout
                className="dashboard"
                leftHeader="Require Review"
                leftSubheader="Immediate fall detection response queue"
                leftClassName="box-shadow-red"
                leftContents={
                    <div className="dashboard__queue-container overflow-y-scroll">
                        {renderQueueCards(getFallsToReview())}
                    </div>
                }
                rightHeader="Confirm Review"
                rightSubheader="Fall detection confirmation queue"
                rightClassName="box-shadow-darkgray"
                rightContents={
                    <div className="dashboard__queue-container overflow-y-scroll">
                        {renderQueueCards(getFallsToConfirm())}
                    </div>
                }
            />
        );
    }

    return isMobileDevice() ? renderDashboardTabLayout() : renderDashboardColumnLayout();
}

export default Dashboard;
