import OneColumnLayout from '../OneColumnLayout/OneColumnLayout';
import './TwoColumnLayout.scss';

interface TwoColumnLayoutProps {
    className?: string;
    leftContents: React.ReactNode;
    leftHeader: string;
    leftSubheader?: string;
    leftClassName?: string;
    rightContents: React.ReactNode;
    rightHeader: string;
    rightSubheader?: string;
    rightClassName?: string;
    rightColumnVisible?: boolean;
}

/**
 * A template for a two-column layout.
 * @param param
 * @param param.className Additional classnames for container div
 * @param param.leftContents Contents of the left column
 * @param param.leftHeader Main header of left column
 * @param param.leftSubheader Subheader text of left column
 * @param param.leftClassName Additional classnames for left column
 * @param param.rightContents Contents of the right column
 * @param param.rightHeader Main header of right column
 * @param param.rightSubheader Subheader text of right column
 * @param param.rightClassName Additional classnames for right column
 * @param param.rightColumnVisible Show right column?
 */
function TwoColumnLayout({
    className,
    leftContents,
    leftHeader,
    leftSubheader,
    leftClassName,
    rightContents,
    rightHeader,
    rightSubheader,
    rightClassName,
    rightColumnVisible = true
}: TwoColumnLayoutProps) {
    return (
        <div className={`row h-100 p-3 m-0 ${className}`}>
            <div className="col-7">
                <OneColumnLayout
                    className={leftClassName}
                    header={leftHeader}
                    subheader={leftSubheader}
                    contents={leftContents}
                />
            </div>
            {rightColumnVisible && (
                <div className="col-5">
                    <OneColumnLayout
                        className={`${rightClassName} right-col`}
                        header={rightHeader}
                        subheader={rightSubheader}
                        contents={rightContents}
                    />
                </div>
            )}
        </div>
    );
}

export default TwoColumnLayout;
