import { useState, useEffect } from 'react';
import axios from 'axios';

export default function Logins() {
    const [users, setUsers] = useState<any[]>([]);
    const API = process.env.REACT_APP_BACKEND_API;

    useEffect(() => {
        const checkLoggedinUsers = async () => {
            try {
                await axios.get(`${API}/logins`, { withCredentials: true }).then((res) => {
                    if (res.status === 200) {
                        setUsers(res.data);
                    }
                });
            } catch (error) {
                console.error(error);
            }
        };
        checkLoggedinUsers();
    }, []);
    return (
        <div className="one-col-layout col-12 d-flex flex-column rounded p-4 m-0">
            <h2 className="text-dark">Current Logins</h2>
            {users.length === 0 ? (
                <p>No users (how did you get this?)</p>
            ) : (
                <div className="table-responsive my-2 no-border-collapse">
                    <table className="table d-table table-bordered table-hover no-border-collapse">
                        <thead className="table-header-fixed">
                            <tr>
                                <th className="col-4 align-middle header">Email</th>
                                <th className="col-4 align-middle header">Online Devices</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {users.map((user: any) => (
                                <tr key={user.email}>
                                    <td>
                                        {user.full_name}
                                        <br />
                                        <i>({user.email})</i>
                                    </td>
                                    <td>{user.online_devices}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
