// Screen width breakpoints for devices in pixels
export enum ScreenDevice {
    DESKTOP = 1080,
    TABLET = 992,
    MOBILE = 768
}

export enum Align {
    START = 'start',
    CENTER = 'center',
    END = 'end'
}

export enum ReviewStatus {
    REVIEW_APPROVED = 'IS_FALL',
    REVIEW_REJECTED = 'NOT_FALL'
}

export enum ConfirmStatus {
    CONFIRM_APPROVED = 'CONFIRMED',
    CONFIRM_REJECTED = 'REJECTED'
}

export enum FallStatus {
    REQUIRES_REVIEWING,
    REQUIRES_CONFIRMATION,
    HISTORIC_FALL
}

export enum HistoricFall {
    APPROVED = 'approved',
    REJECTED = 'rejected',
}
