import { useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { HistoricFall } from '../../../types/enums';
import DateInput from '../../common/DateInput/DateInput';
import FilterControlsLayout from '../FilterControlsLayout/FilterControlsLayout';
import Lightbox from '../../common/Lightbox/Lightbox';
import OneColumnLayout from '../OneColumnLayout/OneColumnLayout';
import Paginator from '../../common/Paginator/Paginator';
import SearchInput from '../../common/SearchInput/SearchInput';
import TwoColumnLayout from '../TwoColumnLayout/TwoColumnLayout';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useFallSearchOptions from '../../../hooks/useFallSearchOptions';
import {
    getTimeDifference,
    formatDateToDisplay,
    formatTimeToDisplay,
    formatDateTimeToDisplay
} from '../../../utils/timeUtils';
import './FallHistoryPageLayout.scss';
// import IncidentModal from '../../common/IncidentModal/IncidentModal';
// import { FallContext } from '../../../contexts/FallContext';

interface FallHistoryPageLayoutProps {
    className?: 'falls-approved' | 'falls-rejected';
    type: HistoricFall;
    header: string;
    subheader: string;
}

/**
 * Template layout for Falls Approved and Falls Rejected page.
 */
function FallHistoryPageLayout({ className, header, subheader, type }: FallHistoryPageLayoutProps) {
    const { isDesktopDevice, isMobileDevice } = useMediaQuery();
    const [fallSelected, setFallSelected] = useState({} as Fall);
    const [rightColumnVisible, setRightColumnVisible] = useState(false);
    const [lightboxVisible, setLightboxVisible] = useState(false);
    const tableRef = useRef<HTMLElement>();

    const {
        falls,
        page,
        rowCount,
        validSearch,
        updateSearchPage,
        updateSearchDate,
        updateSearchDeviceId
    } = useFallSearchOptions(type);

    return isMobileDevice() ? (
        <OneColumnLayout
            className={`${className} history-page-layout`}
            header={header}
            subheader={subheader}
            contents={<FallsTableColumn />}
        />
    ) : (
        <TwoColumnLayout
            className={`${className} history-page-layout`}
            leftHeader={header}
            leftSubheader={subheader}
            leftContents={<FallsTableColumn />}
            rightHeader="Event Details"
            rightContents={<EventDetailsColumn />}
            rightColumnVisible={rightColumnVisible}
        />
    );

    /**
     * Displays a table of all falls. Clicking on a row shows details of
     * a Fall in either a lightbox on mobile, or a column if any other device.
     */
    function FallsTableColumn() {
        /**
         * Handle when user selected a row.
         * On mobile, shows details of selected in a lightbox.
         * On other devices, a column appears beside table to show details.
         */
        const handleRowClick = (fall: Fall) => {
            setFallSelected(fall);
            if (isMobileDevice()) {
                setLightboxVisible(true);
            } else {
                setRightColumnVisible(true);
            }
        };

        return lightboxVisible ? (
            <Lightbox
                className="bg-white p-4"
                visible={lightboxVisible}
                onClose={() => setLightboxVisible(false)}
            >
                <EventDetailsColumn />
            </Lightbox>
        ) : (
            <div className="d-flex flex-column flex-grow-1">
                <FallsFilterControls />
                <FallsTable handleRowClick={handleRowClick} />
            </div>
        );
    }

    /**
     * Displays details of the selected Fall.
     */
    function EventDetailsColumn() {
        return (
            <div className="d-flex flex-column">
                {eventDetailsFallDetails()}
                {eventDetailsMetricData()}
                {eventDetailsEmergencyContact()}
            </div>
        );
    }

    /**
     * Displays controls that can be used to filter Falls in the Falls table.
     */
    function FallsFilterControls() {
        // TODO: May need validation
        return (
            <FilterControlsLayout>
                <DateInput
                    fluid
                    label="Date"
                    htmlFor="date"
                    onChange={(value) => {
                        updateSearchPage(1);
                        updateSearchDate(value);
                    }}
                    placeholder="Select Date..."
                />
                <SearchInput
                    fluid
                    label="Device ID"
                    htmlFor="deviceId"
                    maxLength={11}
                    onChange={(value) => {
                        updateSearchPage(1);
                        updateSearchDeviceId(value);
                    }}
                    onChangeLengths={[4, 8]}
                    placeholder="Full Device ID..."
                />
                <Alert show={!validSearch} variant="danger">
                    No matches were returned with these filter settings.
                </Alert>
            </FilterControlsLayout>
        );
    }

    /**
     * Display Falls in a table.
     * @param handleRowClick Handle what happens when a row is clicked
     */
    function FallsTable({ handleRowClick }: { handleRowClick: (fall: Fall) => void }) {
        return (
            <div className="d-flex flex-column flex-grow-1 justify-content-center">
                {/* @ts-ignore */}
                <div className="table-responsive my-2 no-border-collapse" ref={tableRef}>
                    <table className="table d-table table-bordered table-hover no-border-collapse">
                        <thead className="table-header-fixed">
                            <tr>
                                <th className="col-4 align-middle header">Event Date</th>
                                <th className="col-4 align-middle header">Device ID</th>
                            </tr>
                        </thead>

                        <tbody className="">
                            {falls.map((fall: Fall) => (
                                <tr key={fall.id} onClick={() => handleRowClick(fall)}>
                                    <td>{formatDateToDisplay(fall.insertionTime)}</td>
                                    <td>{fall.deviceId}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {rowCount != null && (
                    <Paginator
                        page={page}
                        pagesVisible={5}
                        itemCount={rowCount}
                        itemsPerPage={10}
                        onClick={(newPageNum) => {
                            updateSearchPage(newPageNum);
                            tableRef!.current!.scrollTop = 0;
                        }}
                    />
                )}
            </div>
        );
    }

    /**
     * Displays selected fall's event timestamp and deviceID.
     */
    function eventDetailsFallDetails() {
        return (
            <div className="d-flex flex-wrap mb-3">
                <h6 className="mr-5">
                    <b className="text-dark">Event Timestamp: </b>
                    {formatDateTimeToDisplay(fallSelected.insertionTime)}
                </h6>
                <h6>
                    <b className="text-dark">Device ID:</b> {fallSelected.deviceId}
                </h6>
            </div>
        );
    }

    /**
     * Displays selected fall's metric data.
     */
    function eventDetailsMetricData() {
        return (
            <div className="d-flex flex-column mb-5">
                <h5 className="text-dark font-weight-bold">Metric Data</h5>
                <div className={`${isDesktopDevice() && 'px-4'}`}>
                    <div className="row">
                        <b className="custom-col-1">Reviewed By</b>
                        <span className="custom-col-2">
                            {fallSelected.reviewUserName ? fallSelected.reviewUserName : 'N/A'}
                        </span>
                    </div>
                    <div className="row">
                        <b className="custom-col-1">Response Time</b>
                        <span className="custom-col-2">
                            <span className="text-nowrap mr-4">
                                {fallSelected.reviewTime
                                    ? formatTimeToDisplay(fallSelected.reviewTime)
                                    : 'N/A'}
                            </span>
                            <span className="text-nowrap">
                                {getTimeDifference(
                                    fallSelected.reviewTime,
                                    fallSelected.insertionTime
                                )}
                            </span>
                        </span>
                    </div>
                    <div className="row">
                        <b className="custom-col-1">Confirmed By</b>
                        <span className="custom-col-2">
                            {fallSelected.confirmUserName ? fallSelected.confirmUserName : 'N/A'}
                        </span>
                    </div>
                    <div className="row">
                        <b className="custom-col-1">Confirmation Response Time</b>
                        <span className="custom-col-2">
                            <span className="text-nowrap mr-4">
                                {fallSelected.confirmTime
                                    ? formatTimeToDisplay(fallSelected.confirmTime)
                                    : 'N/A'}
                            </span>
                            <span className="text-nowrap">
                                {getTimeDifference(
                                    fallSelected.confirmTime,
                                    fallSelected.reviewTime
                                )}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Displays a checkbox of if emergency contact was notified for the selected fall.
     */
    function eventDetailsEmergencyContact() {
        return (
            <span className="d-flex align-items-center mb-4">
                <h5 className="m-0 mr-4 text-dark font-weight-bold">Emergency Contact Notified</h5>
                <input
                    style={{ height: 24, width: 24 }}
                    type="checkbox"
                    disabled
                    checked={fallSelected.notifyTime != null}
                />
            </span>
        );
    }
}

export default FallHistoryPageLayout;
