import { useContext } from 'react';
import Countdown from '../Countdown/Countdown';
import IncidentButtonRow from '../IncidentButtonRow/IncidentButtonRow';
import Lightbox from '../Lightbox/Lightbox';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { formatDateTimeToDisplay } from '../../../utils/timeUtils';
import './IncidentModal.scss';
import { FallContext } from '../../../contexts/FallContext';

interface IncidentModalProps {
    fall: Fall;
    visible: boolean;
    onClose: () => void;
}

function IncidentModal({ fall, visible, onClose }: IncidentModalProps) {
    const { isMobileDevice } = useMediaQuery();
    const fallDateTime = formatDateTimeToDisplay(fall.insertionTime);
    const { getImageUrl } = useContext(FallContext);

    return (
        <Lightbox visible={visible} onClose={onClose}>
            <div data-testid="IncidentModal" className="incident-modal">
                <span className="d-flex align-items-center">
                    {(!isMobileDevice() && !fall.historic) && (
                        <Countdown
                            className="mr-3"
                            to={new Date(fall.insertionTime).getTime()}
                            reviewOffset
                        />
                    )}
                    {fallDateTime}
                </span>
                <div className="incident-modal__image-container">
                    {fall.image && <img src={getImageUrl(fall.image)} alt="Fall" />}
                </div>
                {(!isMobileDevice() && !fall.historic) && <IncidentButtonRow fall={fall} />}
            </div>
        </Lightbox>
    );
}

export default IncidentModal;
