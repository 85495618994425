import React, { useContext } from 'react';
import { SidebarContext } from '../../../contexts/SidebarContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import './Lightbox.scss';

interface LightboxProps {
    /**
     * Boolean to determine if Lightbox is visible
     */
    visible: boolean;
    /**
     * onClose function
     */
    onClose: () => void;
    /**
     * Contents inside lightbox
     */
    children: React.ReactNode;
    /**
     * Boolean to determine if Lightbox should be full width (no padding)
     */
    fullwidth?: boolean;
    /**
     * Additional classnames
     */
    className?: string;
}

function LightBox({ children, visible, onClose, fullwidth = false, className }: LightboxProps) {
    const { isMobileDevice } = useMediaQuery();
    const { getSidebarWidth } = useContext(SidebarContext);
    const offset = 15;

    const modalStyle = {
        top: offset + (fullwidth ? 0 : 80),
        left: offset + (fullwidth ? 0 : getSidebarWidth()),
        right: offset,
        bottom: offset
    };

    if (!visible) {
        return null;
    }

    const lightboxModifier = isMobileDevice() ? 'lightbox--mobile' : '';

    return (
        <div
            data-testid="Lightbox"
            className={`lightbox ${lightboxModifier} ${className}`}
            style={!isMobileDevice() ? modalStyle : undefined}
        >
            <div
                className="bi bi-x-lg lightbox__close"
                onClick={() => {
                    onClose();
                }}
            />
            <div className={`lightbox__content ${isMobileDevice() ? '' : 'p-4'}`}>{children}</div>
        </div>
    );
}

export default LightBox;
