import React, { useEffect, useRef, useState } from 'react';

/**
 * Calculates the dimensions of a components that uses the provided ref.
 * Only recalculates on window resizes.
 * You may need to use a ts-ignore when applying the ref to an element.
 *
 * Losely based on implementation of the following source:
 * https://stackoverflow.com/questions/49058890/how-to-get-a-react-components-size-height-width-before-render
 *
 * @returns An array where the first value is the ref to be added to the component
 * that requires calculation, and the second/third values are the width/height values as numbers.
 */
const useComponentDimensions = (): [
    React.MutableRefObject<HTMLElement | undefined>,
    number,
    number
] => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const ref = useRef<HTMLElement>();

    const handleRecalculation = () => {
        if (ref.current) {
            if (
                ref.current.offsetWidth !== dimensions.width ||
                ref.current.offsetHeight !== dimensions.height
            ) {
                setDimensions({
                    width: ref.current.offsetWidth,
                    height: ref.current.offsetHeight
                });
            }
        }
    };

    useEffect(() => {
        // Attach event listener on resize; run once
        window.addEventListener('resize', handleRecalculation);
        handleRecalculation();
    }, []);

    return [ref, dimensions.width, dimensions.height];
};

export default useComponentDimensions;
