import { createContext, useState } from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

interface SidebarState {
    isSidebarVisible: boolean;
    toggleSidebarVisibility: () => void;
    hideSidebar: () => void;
    getSidebarWidth: () => number;
}

export const SidebarContext = createContext<SidebarState>({
    isSidebarVisible: false,
    toggleSidebarVisibility: () => {},
    hideSidebar: () => {},
    getSidebarWidth: () => 0
});

export const useSidebarState = (): SidebarState => {
    const { isMobileDevice, isTabletDevice, isDesktopDevice } = useMediaQuery();
    /**
     * State used to keep track of the sidebar's visibility.
     */
    const [showSidebar, setShowSidebar] = useState(false);

    /**
     * Toggle the visibility of the sidebar.
     */
    const toggleSidebarVisibility = () => {
        setShowSidebar(!showSidebar);
    };

    /**
     * Closes the sidebar.
     */
    const hideSidebar = () => {
        setShowSidebar(false);
    };

    /**
     * Calculates and returns the width of the sidebar
     */
    const getSidebarWidth = () => {
        if (isMobileDevice()) {
            return 0;
        } else if (isTabletDevice()) {
            return 140;
        } else if (isDesktopDevice()) {
            return 220;
        } else {
            return 0;
        }
    };

    return ({
        isSidebarVisible: showSidebar,
        toggleSidebarVisibility,
        hideSidebar,
        getSidebarWidth
    });
};
