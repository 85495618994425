import { useState } from 'react';
import DatePicker from 'react-datepicker';
import './DateInput.scss';

interface DateInputProps extends InputErrorProps, InputLabelProps {
    onChange: (value: Date | null) => void; // needs to be date for library
    className?: string;
    labelWidth?: number;
    fluid?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

/**
 * Date input component.
 * @param param
 * @param param.className Additioonal classnames
 * @param param.label Label left of input
 * @param param.htmlFor Label htmlFor
 * @param param.onChange What happens when value is changed
 * @param param.labelWidth Can set a fixed label width
 * @param param.fluid If true, input fills the remaining width
 * @param param.disabled Disable control if true
 * @param param.placeholder Placeholder text for input
 */
function DateInput({
    className,
    label,
    htmlFor,
    onChange,
    labelWidth,
    fluid,
    disabled = false,
    placeholder
}: DateInputProps) {
    const [value, setValue] = useState<Date | null>(null);

    return (
        <div
            data-testid="DateInput"
            className={`date-input ${fluid && 'date-input--fluid'} 
            d-flex align-items-center ${className}`}
        >
            <label
                data-testid="DateInput-label"
                className="text-dark text-nowrap font-weight-bold my-0 mr-3"
                style={{ minWidth: labelWidth }}
                htmlFor={htmlFor}
            >
                {label}
            </label>
            <DatePicker
                id={htmlFor}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={value}
                onChange={(e) => {
                    setValue(e);
                    onChange(e);
                }}
                isClearable
                disabled={disabled}
                placeholderText={placeholder}
            />
            {/* Changes icon to circled x for clearing field if there is a value */}
            {value ? (
                <i
                    className="date-input__icon bi bi-x-circle"
                    onClick={() => {
                        setValue(null);
                        onChange(null);
                    }}
                />
            ) : (
                <i className="date-input__icon bi bi-calendar" />
            )}
        </div>
    );
}

export default DateInput;
