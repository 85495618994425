/**
 * Adds minutes to a date
 */
export function addMinutes(date: Date, minutes: number) {
    const minutesToMilliseconds = 60 * 1000;
    return new Date(date.getTime() + minutes * minutesToMilliseconds);
}

/**
 * Formats minutes, and seconds into a mm:ss time string format
 */
export function formatTimeToString(minutes: number, seconds: number) {
    const m = `${minutes < 10 ? `0${minutes}` : minutes}`;
    const s = `${seconds < 10 ? `0${seconds}` : seconds}`;
    return `${m}:${s}`;
}

/**
 * Takes a Date as a String and formats Time for display 'hh:mm:ss AM/PM'
 * @param date
 * @returns String
 */
export function formatTimeToDisplay(date: string) {
    const newDate = new Date(date);
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();

    const twelveHour = hours < 12 || hours === 24 ? 'AM' : 'PM';
    const convertedHours = hours % 12 || 12;
    const convertedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const convertedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    const formattedTime = `${convertedHours}:${convertedMinutes}:${convertedSeconds}${twelveHour}`;
    return formattedTime;
}

/**
 * Takes a Date as a String and formats Date for display 'dd/MM/yyyy'
 * @param date
 * @returns String
 */
export function formatDateToDisplay(date: string) {
    /* When converting the original date string, there is a chance that
    the day would be offseted by one due to how a date is parsed.
    Check https://stackoverflow.com/a/31732581 for more details. */

    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1; // month counts from 0
    const day = dateObj.getDate();

    const convertedMonth = month < 10 ? `0${month}` : month;
    const convertedDay = day < 10 ? `0${day}` : day;

    const formattedDate = `${convertedDay}/${convertedMonth}/${year}`;
    return formattedDate;
}

/**
 * Takes a Date as a String and formats Date for display dd/MM/yyyy hh:mm:ss
 * @param date Date as string
 * @param showDateThenTime If true, show date then time, else show time then date
 */
export function formatDateTimeToDisplay(date: string | null, showDateThenTime: boolean = true) {
    if (!date) return '';
    return showDateThenTime
        ? formatDateToDisplay(date) + ' ' + formatTimeToDisplay(date)
        : formatTimeToDisplay(date) + ' ' + formatDateToDisplay(date);
}

/**
 * Calculates the difference in time between two dates
 * @param date1 First date
 * @param date2 Second date
 * @returns "mm:ss seconds" string
 */
export function getTimeDifference(date1: string | null, date2: string | null) {
    if (!date1 || !date2) return '';
    const dateTime1 = new Date(date1).getTime();
    const dateTime2 = new Date(date2).getTime();
    const diffMilliseconds = Math.abs(dateTime1 - dateTime2);

    const minutes = Math.floor(diffMilliseconds / 60000);
    const seconds = Math.round((diffMilliseconds % 60000) / 1000);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds + ' mins';
}

/**
 * Formats date for sending to backend for DB query
 * @param date Date to convert into a string the query will accept
 */
export function formatDateForQuery(date: Date | null) {
    if (!date) return '';
    const day = date.getDate();
    const month = date.getMonth() + 1; // month counts from 0
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

/**
 * Used to generate a datetime string offset by the provided amount.
 * Predomentantly
 * @param minutesOffset The number of minutes to offset the datetime by.
 * @returns String representation of an ISO datetime (offset by the provided amount).
 */
export const generateIsoDateWithOffset = (minutesOffset: number): string => {
    const currentDate = new Date().getTime();
    const offset = minutesOffset * 1000 * 60;
    return new Date(currentDate + offset).toISOString();
};
