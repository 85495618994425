import { useContext, useState } from 'react';
import { ReviewStatus } from '../../../types/enums';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Countdown from '../Countdown/Countdown';
import IncidentModal from '../IncidentModal/IncidentModal';
import { formatDateTimeToDisplay } from '../../../utils/timeUtils';
import './IncidentCard.scss';
import IncidentButtonRow from '../IncidentButtonRow/IncidentButtonRow';
import useComponentDimensions from '../../../hooks/useComponentDimensions';
import { FallContext } from '../../../contexts/FallContext';

interface IncidentCardProps {
    /**
     * The fall event to render details about.
     */
    fall: Fall;
}

function IncidentCard({ fall }: IncidentCardProps) {
    const [visible, setVisible] = useState(false);
    const { isMobileDevice } = useMediaQuery();
    const [containerRef, containerWidth] = useComponentDimensions();
    const { removeFall, getImageUrl } = useContext(FallContext);

    /**
     * Renders Incident status for Confirm items
     */
    function renderIncidentStatus() {
        return (
            <>
                {fall.reviewFallDetected === ReviewStatus.REVIEW_APPROVED && fall.reviewTime && (
                    <div className="incident-card__status">
                        <span className="mr-2">Status: </span>
                        <div>Fall Detected</div>
                    </div>
                )}
                {fall.reviewFallDetected === ReviewStatus.REVIEW_REJECTED && fall.reviewTime && (
                    <div className="incident-card__status">
                        <span className="mr-2">Status: </span>
                        <div>No Fall Detected</div>
                    </div>
                )}
            </>
        );
    }

    /**
     * Renders formatted Date and Time
     */
    function renderDateTime() {
        if (fall.reviewTime) return null;

        const fallDateTime = formatDateTimeToDisplay(fall.insertionTime, false);
        return (
            <div className={`py-2 ${isMobileDevice() ? 'date-time--mobile' : 'date-time'}`}>
                <div className="time">{fallDateTime}</div>
            </div>
        );
    }

    return (
        // @ts-ignore
        <div data-testid="IncidentCard" className="incident-card" ref={containerRef}>
            <div className="incident-card__info">
                <Countdown
                    className="mr-3"
                    to={new Date(fall.insertionTime).getTime()}
                    reviewOffset
                    compact={containerWidth < 400}
                    onTimeout={() => removeFall(fall.id)}
                />
                {renderIncidentStatus()}
                {isMobileDevice() && renderDateTime()}
            </div>
            <div className="incident-card__body my-3">
                <div
                    className="image-container"
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    {fall.image && <img src={getImageUrl(fall.image)} alt="Fall" />}
                </div>
                {!isMobileDevice() && renderDateTime()}
            </div>
            <div className="incident-card__footer">
                <IncidentButtonRow fall={fall} />
            </div>
            <IncidentModal
                fall={fall}
                visible={visible}
                onClose={() => {
                    setVisible(false);
                }}
            />
        </div>
    );
}

export default IncidentCard;
