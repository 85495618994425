import { useContext, useEffect, useState } from 'react';
import { FallContext } from '../contexts/FallContext';
import { HistoricFall } from '../types/enums';
import { formatDateForQuery } from '../utils/timeUtils';

/**
 * Used to filter Falls from a given FallList.
 * Can filter falls by their date, and/or device Id.
 */
const useFallSearchOptions = (historicFallType: HistoricFall) => {
    const [falls, setFalls] = useState<Fall[]>([]);
    const [page, setPage] = useState(1);
    const [date, setDate] = useState<Date | null>(null);
    const [deviceId, setDeviceId] = useState('');
    const [rowCount, setRowCount] = useState(0);
    const [validSearch, setValidSearch] = useState(true);

    const { fetchHistoricFalls, fetchHistoricCount } = useContext(FallContext);

    /**
     * Returns true if there is a date or deviceId value.
     * Is used to only show "no matches found warning" when there are search values.
     */
    const queriesHaveValue = () => date || deviceId.length > 0;

    // Retrieves falls/number of rows from backend when page, date or deviceId changes
    useEffect(() => {
        setValidSearch(true);
        fetchHistoricFalls(historicFallType, page, deviceId, formatDateForQuery(date))
            .then((_falls) => setFalls(_falls))
            .catch(() => queriesHaveValue() && setValidSearch(false));
        fetchHistoricCount(historicFallType, deviceId, formatDateForQuery(date))
            .then((_rowCount) => setRowCount(_rowCount))
            .catch(() => queriesHaveValue() && setValidSearch(false));
    }, [page, deviceId, date, historicFallType]);

    // Reset page to 1 when row count changes
    useEffect(() => setPage(1), [rowCount]);

    return {
        falls,
        page,
        rowCount,
        validSearch,
        updateSearchPage: setPage,
        updateSearchDate: setDate,
        updateSearchDeviceId: setDeviceId
    };
};

export default useFallSearchOptions;
