import './IncidentButtonRow.scss';
import { useContext } from 'react';
import { FallContext } from '../../../contexts/FallContext';
import { Align, FallStatus } from '../../../types/enums';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { getFallStatus } from '../../../utils/fallUtils';

interface IncidentButtonRowProps {
    /**
     * The fall to render the action buttons for
     */
    fall: Fall;
    /**
     * Whether the button row should be left, center, or right aligned.
     */
    align?: Align;
}

/**
 * Buttons used to action fall events by reviewiewing or confirming them.
 * Will only render if actions are able to be taken, otherwise nothing will be rendered.
 */
function IncidentButtonRow({ fall, align = Align.END }: IncidentButtonRowProps) {
    const fallContext = useContext(FallContext);
    const { isDesktopDevice } = useMediaQuery();

    /**
     * Don't render anything if not online.
     */
    if (!fallContext.online) {
        return null;
    }

    /**
     * Don't render anything if fall is not actionable.
     */
    if (fall.confirmFallDetected !== null || fall.confirmTime) {
        return null;
    }

    /**
     * Whether buttons should stretch full width or not.
     */
    const btnFlex = () => {
        if (isDesktopDevice()) {
            return 'btn-flex-0';
        } else {
            return 'btn-flex-1';
        }
    };

    /**
     * The buttons to action incedents that require review.
     * @returns JSX.Element
     */
    const renderReviewButtons = () => (
        <>
            <button
                type="button"
                className={`btn btn-primary ${btnFlex()}`}
                onClick={() => {
                    fallContext.reviewFallApprove(fall.id);
                }}
            >
                Fall detected
            </button>
            <button
                type="button"
                className={`btn btn-secondary ${btnFlex()}`}
                onClick={() => {
                    fallContext.reviewFallReject(fall.id);
                }}
            >
                No fall detected
            </button>
        </>
    );

    /**
     * The buttons to action incedents that require confirmation.
     * @returns JSX.Element
     */
    const renderConfirmButtons = () => (
        <>
            <button
                type="button"
                className={`btn btn-primary ${btnFlex()}`}
                onClick={() => {
                    fallContext.confirmFallApprove(fall.id);
                }}
            >
                Confirm
            </button>
            <button
                type="button"
                className={`btn btn-danger ${btnFlex()}`}
                onClick={() => {
                    fallContext.confirmFallReject(fall.id);
                }}
            >
                Reject
            </button>
        </>
    );

    return (
        <div className={`d-flex w-100 justify-content-${align} incidentbuttonrow`}>
            {getFallStatus(fall) === FallStatus.REQUIRES_REVIEWING
                ? renderReviewButtons()
                : renderConfirmButtons()}
        </div>
    );
}

export default IncidentButtonRow;
