import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import useMediaQuery from '../../hooks/useMediaQuery';
import './Login.scss';

function Login() {
    const { login, isAuthed } = useContext(AuthContext);
    const { isMobileDevice } = useMediaQuery();

    if (isAuthed === false) {
        return (
            <div className="login__behind-container py-5 px-4 bg-gradient-primary">
                <div className="login__container overflow-hidden shadow-lg">
                    <div
                        className={`login__row ${
                            isMobileDevice() ? 'flex-column' : 'flex-row mr-0'
                        }`}
                    >
                        {!isMobileDevice() && (
                            <div className="login__col d-flex px-0">
                                <img
                                    src={`${process.env.REACT_APP_HOME_ROUTE}/login-image.png`}
                                    alt="Login"
                                    className="login__image"
                                />
                            </div>
                        )}
                        <div className="login__col">
                            <div
                                className={`${
                                    !isMobileDevice() &&
                                    'd-flex flex-column justify-content-center align-baseline h-100'
                                }`}
                            >
                                <div className="d-flex justify-content-center align-baseline">
                                    <img
                                        src={`${process.env.REACT_APP_HOME_ROUTE}/home-guardian-logo.jpg`}
                                        alt="logo"
                                        className="login__logo justify-self-center"
                                    />
                                </div>
                                <h1 className="h4 text-primary login__title">Review Portal</h1>
                                <button
                                    type="button"
                                    className="login__btn btn-primary btn-lg"
                                    onClick={login}
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <Navigate to="/dashboard" replace />;
    }
}

export default Login;
