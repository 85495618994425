import { HistoricFall } from '../../../types/enums';
import FallHistoryPageLayout from '../../../components/layout/FallHistoryPageLayout/FallHistoryPageLayout';

function FallsRejected() {
    return (
        <FallHistoryPageLayout
            className="falls-rejected"
            header="Rejected Falls"
            subheader="Select a line item from the table to review rejected fall details"
            type={HistoricFall.REJECTED}
        />
    );
}

export default FallsRejected;
