import { HistoricFall } from '../../../types/enums';
import FallHistoryPageLayout from '../../../components/layout/FallHistoryPageLayout/FallHistoryPageLayout';

function FallsApproved() {
    return (
        <FallHistoryPageLayout
            className="falls-approved"
            header="Approved Falls"
            subheader="Select a line item from the table to review approved fall details"
            type={HistoricFall.APPROVED}
        />
    );
}

export default FallsApproved;
