import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { FallContext } from '../../../contexts/FallContext';
import { SidebarContext } from '../../../contexts/SidebarContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Circle from '../../common/Circle/Circle';
import './Navbar.scss';

function Navbar() {
    const { toggleSidebarVisibility } = useContext(SidebarContext);
    const { fallsToActionCount, disconnectSocket } = useContext(FallContext);
    const { isMobileDevice } = useMediaQuery();

    /**
     * logout functionality below, navigation happens here, can't call hook in logout function
     * */
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    async function performLogout(e: { preventDefault: () => void }) {
        e.preventDefault();
        await logout();
        disconnectSocket();
        navigate('/login', { replace: true });
    }

    return (
        <nav className="nav bg-primary align-items-center px-2 pr-4">
            {homeGuardianLogo()}
            <div className="d-flex align-items-center ml-auto">
                {notificationIcon()}
                {profileIcon()}
                {mobileOnlyListIcon()}
            </div>
        </nav>
    );

    /**
     * Home Guardian logo that redirect to the Index page when clicked.
     */
    function homeGuardianLogo() {
        return (
            <Link to="/">
                <img
                    src={`${process.env.REACT_APP_HOME_ROUTE}/logo-nav.png`}
                    alt="Home Guardian logo"
                    className="p-2"
                    style={{ height: '4rem' }}
                />
            </Link>
        );
    }

    /**
     * Bell notification icon that rediects to the Dashboard when clicked.
     */
    function notificationIcon() {
        const showRedCircle = (fallsToActionCount.review + fallsToActionCount.confirm) > 0;
        return (
            <Link to="/Dashboard" className="d-flex">
                <i className="nav__icon bi bi-bell " />
                <Circle
                    className={`bi-bell-notif ${showRedCircle && 'bg-danger'}`}
                    diameter="1rem"
                />
            </Link>
        );
    }

    /**
     * Profile icon that shows a dropdown with a logout option when clicked.
     */
    function profileIcon() {
        return (
            <div className="nav-item dropdown">
                <a
                    className="nav__nav-link dropdown-toggle"
                    href="#top"
                    role="button"
                    aria-label="profile"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i className="nav__icon bi bi-person-circle" />
                </a>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a className="dropdown-item" href="#top" role="button" onClick={performLogout}>
                        Logout
                    </a>
                </div>
            </div>
        );
    }

    /**
     * A List icon (three-bars icon) that shows the sidebar when clicked.
     */
    function mobileOnlyListIcon() {
        return (
            isMobileDevice() && (
                <i className="nav__icon bi bi-list" onClick={toggleSidebarVisibility} />
            )
        );
    }
}

export default Navbar;
