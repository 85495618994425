import { useEffect, useState } from 'react';

/**
 * Is used in the Countdown component to calculate the days, hours, minutes,
 * and/or seconds to a target date. Timer stops once it reaches zero
 */
const useCountdown = (targetDate: Date) => {
    const targetMilliseconds = new Date(targetDate).getTime();
    // Calculates the initial time remaining
    let initialRemainingMillseconds = targetMilliseconds - new Date().getTime();
    if (initialRemainingMillseconds <= 0) initialRemainingMillseconds = 0;

    const [countDown, setCountDown] = useState(initialRemainingMillseconds);

    useEffect(() => {
        // Calculates time remaining every second
        const interval = setInterval(() => {
            const remainingMillseconds = targetMilliseconds - new Date().getTime();

            if (remainingMillseconds <= 0) {
                setCountDown(0);
                clearInterval(interval);
            } else {
                setCountDown(remainingMillseconds);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    // Calculates time remaining
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    /**
     * Returns true if minutes and seconds equal 0.
     */
    const isOutOfTime = () => minutes <= 0 && seconds <= 0;

    return {
        days,
        hours,
        minutes,
        seconds,
        isOutOfTime
    };
};

export default useCountdown;
