import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import _ from 'lodash';
import { FallStatus, HistoricFall } from '../types/enums';
import { getFallStatus } from '../utils/fallUtils';

const API = process.env.REACT_APP_BACKEND_API!;
const SOCKET_API = process.env.REACT_APP_SOCKET_API!;
let socket: Socket;
const notification = new Audio(`${SOCKET_API}/notification.wav`);

interface OfflineFallState {
    online: false;
    falls: FallList;
    removeFall: (fallId: Fall['id']) => void;
    getFallsToReview: () => FallList;
    getFallsToConfirm: () => FallList;
    fetchHistoricFalls: () => Promise<Fall[]>;
    fetchHistoricCount: () => Promise<number>;
    fallsToActionCount: { review: number; confirm: number };
    getImageUrl: (fallImage: string) => string;
    disconnectSocket: () => void;
}

interface OnlineFallState {
    online: true;
    falls: FallList;
    removeFall: (fallId: Fall['id']) => void;
    getFallsToReview: () => FallList;
    getFallsToConfirm: () => FallList;
    fetchHistoricFalls: (
        type: HistoricFall,
        page?: number,
        deviceId?: string,
        date?: string
    ) => Promise<Fall[]>;
    fetchHistoricCount: (
        type: HistoricFall,
        deviceId: string | undefined,
        date: string | undefined
    ) => Promise<number>;
    fallsToActionCount: { review: number; confirm: number };
    reviewFallApprove: (fallId: Fall['id']) => void;
    reviewFallReject: (fallId: Fall['id']) => void;
    confirmFallApprove: (fallId: Fall['id']) => void;
    confirmFallReject: (fallId: Fall['id']) => void;
    getImageUrl: (fallImage: string) => string;
    disconnectSocket: () => void;
}

interface RowCount {
    rowCount: number;
}

export const FallContext = createContext<OnlineFallState | OfflineFallState>({
    online: false,
    // falls: ({} as FallList),
    removeFall: () => {},
    falls: {},
    getFallsToReview: () => ({} as FallList),
    getFallsToConfirm: () => ({} as FallList),
    fetchHistoricFalls: async () => [],
    fetchHistoricCount: async () => 0,
    fallsToActionCount: { review: 0, confirm: 0 },
    getImageUrl: () => '',
    disconnectSocket: () => {}
});

/**
 * For managing the falls and the socketIO connection used to
 * receive new falls.
 *
 * Use `useContext(FallContext);` to access falls.
 */
export const useFallState = (isAuthed: boolean): OnlineFallState | OfflineFallState => {
    /**
     * State for managing falls.
     */
    const [falls, setFalls] = useState<FallList>({});
    /**
    /**
     * The number of falls assigned to the user that either
     * needs reviewing or confirming.
     */
    const [fallsToActionCount, setFallsToActionCount] = useState({
        review: 0,
        confirm: 0
    });

    /**
     * State for managing the status of the socket connection.
     */
    // eslint-disable-next-line
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        if (isAuthed && socket === undefined) {
            /**
             * Socket is only initialised when user is authenticated
             * and when a Socket connection doesn't already exist
             */
            socket = io(`${SOCKET_API}`, { withCredentials: true, path: '/api/socket.io' });

            /**
             * Event handler for socket connection.
             */
            socket.on('connect', () => {
                setConnected(true);
            });

            /**
             * Event handler for when new fall events are received by the socket
             * connection. Overrides if the fall already exists or adds new entry
             * if fall does not already exist.
             */
            socket.on('repopulate falls', (response: FallList) => {
                setFalls(response);
                setFallsToActionCount(getFallCount(response));
            });

            /**
             * Event handler for when new fall events are received by the socket
             * connection. Overrides if the fall already exists or adds new entry
             * if fall does not already exist.
             */
            socket.on('fall', (response: Fall) => {
                notification.play();
                setFalls((prev) => {
                    setFallsToActionCount((prevCount) => {
                        if (getFallStatus(response) === FallStatus.REQUIRES_REVIEWING) {
                            return { ...prevCount, review: prevCount.review + 1 };
                        } else {
                            return { ...prevCount, confirm: prevCount.confirm + 1 };
                        }
                    });
                    return {
                        ...prev,
                        [response.id]: response
                    };
                });
            });

            /**
             * Event handler for when a fall event has been actioned and
             * fall should be removed for actioning user
             */
            socket.on('remove fall', (response: Fall['id']) => {
                removeFall(response);
            });
        }
        // Todo: add cleanup method
    }, [isAuthed]);

    const getFallCount = (fallList: FallList): OnlineFallState['fallsToActionCount'] => {
        let reviewCount = 0;
        let confirmCount = 0;

        Object.values(fallList).forEach((fall) => {
            if (getFallStatus(fall) === FallStatus.REQUIRES_REVIEWING) {
                reviewCount++;
            }
            if (getFallStatus(fall) === FallStatus.REQUIRES_CONFIRMATION) {
                confirmCount++;
            }
        });

        return { review: reviewCount, confirm: confirmCount };
    };

    /**
     * Filters and returns the fall list to just the falls that
     * needing reviewing.
     */
    const getFallsToReview = () => {
        const fallsToReview: FallList = {};

        Object.values(falls).forEach((fall) => {
            // If no review value
            if (getFallStatus(fall) === FallStatus.REQUIRES_REVIEWING) {
                fallsToReview[fall.id] = fall;
            }
        });

        return fallsToReview;
    };

    /**
     * Filters and returns the fall list to just the falls that
     * needing confirming.
     */
    const getFallsToConfirm = () => {
        const fallsToConfirm: FallList = {};

        Object.values(falls).forEach((fall) => {
            if (getFallStatus(fall) === FallStatus.REQUIRES_CONFIRMATION) {
                fallsToConfirm[fall.id] = fall;
            }
        });

        return fallsToConfirm;
    };

    const getImageUrl = (fallImage: string) => {
        const url = `${API}/falls/image?name=${fallImage}`;
        return url;
    };

    /**
     * Gets approved falls from backend with pagination from backend
     */
    const fetchHistoricFalls = async (
        type: HistoricFall,
        page?: number,
        deviceId?: string,
        date?: string
    ) => {
        const route = `${API}/falls/historic?type=${type}`;
        const qPage = `&page=${page ?? 1}`;
        const qDeviceId = deviceId ? `&deviceId=${deviceId}` : '';
        const qDate = date ? `&date=${date}` : '';
        const url = route + qPage + qDeviceId + qDate;

        return await axios
            .get<Fall[]>(url, { withCredentials: true })
            .then((res) => res.data as Fall[]);
    };

    /**
     * Gets the total amount of rows for the table
     */
    const fetchHistoricCount = async (type: HistoricFall, deviceId?: string, date?: string) => {
        const route = `${API}/falls/fallCount?type=${type}`;
        const qDeviceId = deviceId ? `&deviceId=${deviceId}` : '';
        const qDate = date ? `&date=${date}` : '';
        const url = route + qDeviceId + qDate;

        return await axios
            .get<RowCount>(url, { withCredentials: true })
            .then((res) => res.data.rowCount as number);
    };

    /**
     * Removes fall by fall Id from state. Also updates count.
     */
    const removeFall = (fallId: Fall['id']): void => {
        setFalls((prev) => {
            setFallsToActionCount(getFallCount(_.omit(prev, fallId)));
            return _.omit(prev, fallId);
        });
    };

    /**
     * Marks a fall event that requires review as approved.
     *
     * @param fallId The id of the fall event to review.
     */
    const reviewFallApprove = (fallId: Fall['id']): void => {
        socket.emit('review is fall', fallId, () => {});
    };

    /**
     * Marks a fall event that requires review as rejected.
     *
     * @param fallId The id of the fall event to review.
     */
    const reviewFallReject = (fallId: Fall['id']): void => {
        socket.emit('review is not fall', fallId, () => {});
    };

    /**
     * Marks a fall event that requires confirmation as approved.
     *
     * @param fallId The id of the fall event to confirm.
     */
    const confirmFallApprove = (fallId: Fall['id']): void => {
        socket.emit('confirm approve', fallId, () => {});
    };

    /**
     * Marks a fall event that requires confirmation as rejected.
     *
     * @param fallId The id of the fall event to confirm.
     */
    const confirmFallReject = (fallId: Fall['id']): void => {
        socket.emit('confirm reject', fallId, () => {});
    };

    /**
     * Disconnects socket (mainly used for logging out)
     */
    const disconnectSocket = () => {
        socket.disconnect();
        setConnected(false);
    };

    // if (connected) {
    return {
        online: true,
        falls,
        removeFall,
        getFallsToReview,
        getFallsToConfirm,
        fallsToActionCount,
        reviewFallApprove,
        reviewFallReject,
        confirmFallApprove,
        confirmFallReject,
        fetchHistoricFalls,
        fetchHistoricCount,
        getImageUrl,
        disconnectSocket
    };
    // }

    // return {
    //     online: false,
    //     falls,
    //     getFallsToReview,
    //     getFallsToConfirm,
    //     getHistoricFalls,
    //     getHistoricCount,
    //     fallsToActionCount,
    //     approvedFalls,
    //     rejectedFalls
    //     approvedRowCount,
    //     rejectedRowCount,
    //     requestSucceeded,
    //     setRequestSucceeded,
    // };
};
