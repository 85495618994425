import { useEffect, useState } from 'react';
import './Paginator.scss';

interface PaginatorProps {
    /**
     * Current page number.
     */
    page: number;
    /**
     * Number of pages visible in paginator.
     */
    pagesVisible: number;
    /**
     * Total number of items.
     */
    itemCount: number;
    /**
     * Number of items in a page.
     */
    itemsPerPage: number;
    /**
     * What happens when a page is clicked.
     */
    onClick: (page: number) => void;
}

/**
 * A table paginator component.
 * @param param Param
 * @param param.page Current page number.
 * @param param.pagesVisible Number of pages visible in paginator.
 * @param param.itemCount Total number of items.
 * @param param.itemsPerPage Number of items in a page.
 * @param param.onClick What happens when a page is clicked.
 */
function Paginator({
    page,
    pagesVisible = 5,
    itemCount,
    itemsPerPage = 10,
    onClick
}: PaginatorProps) {
    const [currentPage, setCurrentPage] = useState(page);
    const maxPages = Math.ceil(itemCount / itemsPerPage);
    // Num pages either side of current page to keep it centered
    const offset = Math.floor(pagesVisible / 2);

    /**
     * Checks if a page number is between the range of 1 and the number of max pages.
     */
    const isValidPageUpdate = (pageNo: number) => pageNo >= 1 && pageNo <= maxPages;

    /**
     * If input is a valid page number, update page state and emit onClick.
     */
    const pageUpdate = (newPageNo: number) => {
        if (isValidPageUpdate(newPageNo)) {
            setCurrentPage(newPageNo);
            onClick(newPageNo);
        }
    };

    // Overrides local page when a new page props is passed
    useEffect(() => setCurrentPage(page), [page]);

    /**
     * Render a string that displays item count details.
     */
    function countDetails() {
        const currentCount = itemCount === 0 ? '0' : 1 + (page - 1) * itemsPerPage;
        const toCount = page * itemsPerPage > itemCount ? itemCount : page * itemsPerPage;

        return (
            <div className="mb-2">
                Showing {currentCount} to {toCount} of {itemCount} entries
            </div>
        );
    }

    /**
     * Render buttons for numbered pages.
     */
    function renderNumberedPages() {
        let pagesRendered = 1; // includes current page

        return [
            // Render pages before current
            [...Array(pagesVisible - pagesRendered)].map((x, i) => {
                // Calculates how many pages are to the left; ensures
                // there's always the number of 'pagesVisible' displayed
                const offsetLeft =
                    currentPage + offset <= maxPages
                        ? offset
                        : offset + (currentPage + offset - maxPages);
                const toPage = currentPage + i - offsetLeft;

                if (isValidPageUpdate(toPage) && toPage < currentPage) {
                    pagesRendered++;
                    return (
                        <PaginatorButton
                            key={toPage}
                            text={toPage}
                            onClick={() => pageUpdate(toPage)}
                        />
                    );
                }
                return null;
            }),

            // Render current page
            <PaginatorButton
                key={currentPage}
                text={currentPage}
                onClick={() => pageUpdate(currentPage)}
                active
            />,

            // Render remaining number of pages
            [...Array(pagesVisible - pagesRendered)].map((x, i) => {
                const toPage = currentPage + i + 1;
                return (
                    isValidPageUpdate(toPage) && (
                        <PaginatorButton
                            key={toPage}
                            text={toPage}
                            onClick={() => pageUpdate(toPage)}
                        />
                    )
                );
            })
        ];
    }

    return (
        <div className="paginator">
            {countDetails()}
            <nav aria-label="..." className="d-flex justify-content-center">
                <ul className="pagination m-0">
                    <PaginatorButton
                        text="Previous"
                        onClick={() => pageUpdate(currentPage - 1)}
                        disabled={!isValidPageUpdate(currentPage - 1)}
                    />
                    {renderNumberedPages()}
                    <PaginatorButton
                        text="Next"
                        onClick={() => pageUpdate(currentPage + 1)}
                        disabled={!isValidPageUpdate(currentPage + 1)}
                    />
                </ul>
            </nav>
        </div>
    );
}

/**
 * A button for the Paginator.
 * @param param param
 * @param param.text Text inside button.
 * @param param.onClick What happens when the button is clicked.
 * @param param.disabled If true, disables component.
 * @param param.active If true, apply active styling.
 */
function PaginatorButton({
    text,
    onClick,
    disabled,
    active
}: {
    text: number | string;
    onClick: () => void;
    disabled?: boolean;
    active?: boolean;
}) {
    return (
        <li key={`page-${text}`} className={`page-item ${disabled && 'disabled'}`}>
            <button type="button" className={`page-link ${active && 'active'}`} onClick={onClick}>
                {text}
            </button>
        </li>
    );
}

export default Paginator;
