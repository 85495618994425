import { FallStatus } from '../types/enums';

/**
 * Identifies and returns the status of a fall.
 * @param fall The fall event.
 * @returns The status of the fall.
 */
export const getFallStatus = (fall: Fall): FallStatus => {
    if (fall.confirmTime !== null) {
        return FallStatus.HISTORIC_FALL;
    } else if (fall.reviewFallDetected === null) {
        return FallStatus.REQUIRES_REVIEWING;
    } else if (fall.confirmFallDetected === null) {
        return FallStatus.REQUIRES_CONFIRMATION;
    } else {
        return FallStatus.HISTORIC_FALL;
    }
};
