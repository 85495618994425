import React, { useState } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import './FilterControlsLayout.scss';

function FilterControlsLayout({ children }: { children: React.ReactNode }) {
    const { isMobileDevice } = useMediaQuery();
    const [filtersVisible, setFiltersVisible] = useState(false);

    return (
        <div className="filter-controls">
            {!isMobileDevice() ? (
                <h5 className="text-dark font-weight-bold">Filter</h5>
            ) : (
                <i
                    className="filter-controls__icon bi bi-sliders"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                />
            )}

            {!isMobileDevice() || filtersVisible ? (
                <div className="filter-controls__inputs-container my-3">{children}</div>
            ) : null}
        </div>
    );
}

export default FilterControlsLayout;
