import Navbar from './layout/Navbar/Navbar';
import Routing, { routes } from './Routing';
import Sidebar from './layout/Sidebar/Sidebar';
import useMediaQuery from '../hooks/useMediaQuery';
import { useFallState, FallContext } from '../contexts/FallContext';
import { useSidebarState, SidebarContext } from '../contexts/SidebarContext';
import { useAuthState, AuthContext } from '../contexts/AuthContext';

function App() {
    const mediaQuery = useMediaQuery();
    const sidebarState = useSidebarState();
    const authState = useAuthState();
    const fallState = useFallState(authState.isAuthed ?? false);

    return (
        <FallContext.Provider value={fallState}>
            <SidebarContext.Provider value={sidebarState}>
                <AuthContext.Provider value={authState}>
                    {authState.isAuthed && <Navbar />}
                    <div
                        className={`d-flex h-100 ${
                            mediaQuery.isMobileDevice() ? 'flex-column' : 'flex-row'
                        }`}
                    >
                        {authState.isAuthed && <Sidebar routes={routes} />}
                        <div className="content w-100 h-100">
                            <Routing />
                        </div>
                    </div>
                </AuthContext.Provider>
            </SidebarContext.Provider>
        </FallContext.Provider>
    );
}

export default App;
