import axios from 'axios';
import { createContext, useState } from 'react';

interface AuthState {
    isAuthed: boolean | undefined;
    checkAuth: () => void;
    login: () => void;
    logout: () => Promise<void>;
}

export const AuthContext = createContext<AuthState>({
    isAuthed: undefined,
    checkAuth: () => {},
    login: () => {},
    logout: () => Promise.resolve()
});

export const useAuthState = (): AuthState => {
    const [authed, setAuthed] = useState<boolean>();
    const API = process.env.REACT_APP_BACKEND_API;

    const checkAuth = async () => {
        await axios
            .get(`${API}/auth/check`, { withCredentials: true })
            .then((res) => {
                if (res.status === 200) {
                    setAuthed(true);
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setAuthed(false);
                }
            });
    };
    // redirects to jumpcloud to perform login
    function login() {
        window.location.replace(`${API}/auth/login`);
    }
    // ends session on backend
    async function logout() {
        await axios.get(`${API}/auth/logout`, { withCredentials: true }).then(() => setAuthed(false));
    }

    return { isAuthed: authed, checkAuth, login, logout };
};
