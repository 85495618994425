import { useEffect, useState } from 'react';
import useCountdown from '../../../hooks/useCountdown';
import { addMinutes, formatTimeToString } from '../../../utils/timeUtils';
import './Countdown.scss';

interface CountdownProps {
    /**
     * Target date to countdown to
     */
    to: number;
    /**
     * If true, adds 5 minutes to the 'to' param
     */
    reviewOffset?: boolean;
    /**
     * Reduces the sizing to make a more compact countdown.
     */
    compact?: boolean;
    /**
     * Additional classnames.
     */
    className?: string;
    /**
     * What happens when timer reaches 0.
     */
    onTimeout?: () => void;
}

/**
 * Timer Countdown.
 * @param param param
 * @param param.className Additional classnames.
 * @param param.to  Target date to countdown to.
 * @param param.compact Reduces the sizing to make a more compact countdown.
 * @param param.reviewOffset If true, adds 5 minutes to the 'to' param
 * @param param.onTimeout What happens when timer reaches 0.
 */
function Countdown({
    className,
    to,
    reviewOffset = false,
    compact = false,
    onTimeout = () => {}
}: CountdownProps) {
    const reviewOffsetMinutes = 5;
    const toDate = reviewOffset ? addMinutes(new Date(to), reviewOffsetMinutes) : new Date(to);
    const { minutes, seconds, isOutOfTime } = useCountdown(toDate);

    // Do onTimeout action if timer actually count downs to zero (i.e. not start with no timer)
    const [doTimeoutAction, setDoTimeoutAction] = useState(false);
    useEffect(() => {
        if (seconds >= 1) {
            setDoTimeoutAction(true);
        }

        if (doTimeoutAction && isOutOfTime()) {
            onTimeout();
            setDoTimeoutAction(false);
        }
    }, [isOutOfTime]);

    return (
        <div
            data-testid="Countdown"
            className={`d-flex justify-content-center align-items-center p-1 
            ${className} countdown--${compact ? 'compact' : 'regular'} 
            ${getStyle()}`}
        >
            <i className="bi bi-stopwatch mr-2" />
            {formatTimeToString(minutes, seconds)}
        </div>
    );

    /**
     * Returns classnames of styles based off of time remaining
     */
    function getStyle() {
        if (seconds < 0) {
            return 'style--loading';
        } else if (isOutOfTime()) {
            return 'style--timeout';
        } else if (minutes === 0) {
            return 'style--danger';
        } else if (minutes >= 1) {
            return 'style--warning';
        } else {
            return 'style--loading';
        }
    }
}

export default Countdown;
