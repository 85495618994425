import { useState } from 'react';
import './SearchInput.scss';

interface SearchInputProps extends InputProps {
    className?: string;
    maxLength?: number;
    labelWidth?: number;
    fluid?: boolean;
    placeholder?: string;
    onChangeLengths?: number[];
}

/**
 * Text-search input component.
 * @param param
 * @param param.className Additioonal classnames
 * @param param.label Label left of input
 * @param param.htmlFor Label htmlFor
 * @param param.onChange What happens when value is changed
 * @param param.onChangeLengths Lengths of input where onChange can be triggered
 * @param param.maxLength Max string length of search text
 * @param param.labelWidth Can set a fixed label width
 * @param param.fluid If true, input fills the remaining width
 * @param param.disabled Disable control if true
 * @param param.placeholder Placeholder text for input
 */
function SearchInput({
    className,
    label,
    htmlFor,
    onChange,
    maxLength,
    labelWidth,
    fluid,
    disabled = false,
    onChangeLengths,
    placeholder
}: SearchInputProps) {
    const [value, setValue] = useState('');

    return (
        <div
            data-testid="SearchInput"
            className={`search-input ${fluid && 'search-input--fluid'} 
            d-flex align-items-center ${className}`}
        >
            <label
                data-testid="SearchInput-label"
                className="text-dark text-nowrap font-weight-bold my-0 mr-3"
                style={{ minWidth: labelWidth }}
                htmlFor={htmlFor}
            >
                {label}
            </label>
            <input
                id={htmlFor}
                data-testid="SearchInput-input"
                type="search"
                className="form-control"
                value={value}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    if (!maxLength || inputValue.length <= maxLength) {
                        setValue(inputValue);
                    }
                    /**
                     * The below is done so we do not make requests to the backend for every
                     * letter typed into field.
                     * Will fire onChange when max length is hit or field is cleared
                     * to initiate search.
                     */
                    if (
                        (onChangeLengths && onChangeLengths.includes(inputValue.length)) ||
                        inputValue.length === maxLength ||
                        inputValue.length === 0
                    ) {
                        onChange(inputValue);
                    }
                }}
                disabled={disabled}
                placeholder={placeholder}
            />
            {/* Changes icon to circled x for clearing field if there is anything typed */}
            {value ? (
                <i
                    className="search-input__icon bi bi-x-circle"
                    onClick={() => {
                        setValue('');
                        onChange('');
                    }}
                />
            ) : (
                <i className="search-input__icon bi bi-search" />
            )}
        </div>
    );
}

export default SearchInput;
