/**
 * Circle with optional text inside
 * @param param Objectz
 * @param param.className Additional classnames
 * @param param.text Text inside circle
 * @param param.diameter CSS size string of circle (E.g. "1.5rem")
 */
function Circle({
    className,
    text,
    diameter = '2rem'
}: {
    className?: string;
    text?: number | string;
    diameter?: string;
}) {
    return (
        <span
            data-testid="Circle"
            className={`d-flex justify-content-center align-items-center text-white ${className}`}
            style={{ height: `${diameter}`, width: `${diameter}`, borderRadius: '50%' }}
        >
            <span style={{ marginBottom: '3px' }}>{text}</span>
        </span>
    );
}

export default Circle;
