import { useEffect, useState } from 'react';
import { ScreenDevice } from '../types/enums';

/**
 * Used to determine the screen device based off the pixel-width of the screen
 */
const useMediaQuery = () => {
    const [screenDevice, setScreenDevice] = useState(ScreenDevice.DESKTOP);

    // Determines the screen device
    const handleResize = () => {
        const screenWidth = window.innerWidth;
        switch (true) {
            case screenWidth < ScreenDevice.MOBILE:
                return setScreenDevice(ScreenDevice.MOBILE);
            case screenWidth < ScreenDevice.TABLET:
                return setScreenDevice(ScreenDevice.TABLET);
            default:
                return setScreenDevice(ScreenDevice.DESKTOP);
        }
    };

    const isDesktopDevice = () => screenDevice === ScreenDevice.DESKTOP;
    const isTabletDevice = () => screenDevice === ScreenDevice.TABLET;
    const isMobileDevice = () => screenDevice === ScreenDevice.MOBILE;

    useEffect(() => {
        // Attach event listener on resize; run once
        window.addEventListener('resize', handleResize);
        handleResize();
    }, []);

    return {
        isMobileDevice,
        isTabletDevice,
        isDesktopDevice,
    };
};

export default useMediaQuery;
