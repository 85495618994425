import useMediaQuery from '../../../hooks/useMediaQuery';
import './OneColumnLayout.scss';

interface OneColumnLayoutProps {
    className?: string;
    contents: React.ReactNode;
    header: string;
    subheader?: string;
}

/**
 * A template for a one-column layout.
 * @param param
 * @param param.className Additional classnames for container div
 * @param param.contents Contents inside
 * @param param.header Main header text
 * @param param.subheader Subheader text
 */
function OneColumnLayout({ className, contents, header, subheader }: OneColumnLayoutProps) {
    const { isMobileDevice } = useMediaQuery();
    return (
        <div className={`one-col-layout col-12 d-flex flex-column rounded p-4 m-0 ${className}`}>
            <h2 className="text-dark">{header}</h2>
            {!isMobileDevice() && subheader ? <h6 className="text-dark pb-4">{subheader}</h6> : <h6> </h6>}
            {contents}
        </div>
    );
}

export default OneColumnLayout;
