import { Routes, Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import Login from '../pages/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import FallsApproved from '../pages/History/FallsApproved/FallsApproved';
import FallsRejected from '../pages/History/FallsRejected/FallsRejected';
import NoMatch from '../pages/NoMatch/NoMatch';
import Logins from '../pages/Logins';

/**
 * List of routes used in the app.
 */
export const routes: RouteConfig[] = [
    {
        path: '/login',
        name: 'Login',
        exact: true,
        Component: Login,
        secured: false
    },
    {
        path: '/',
        name: 'Home',
        exact: true,
        Component: Dashboard,
        secured: true
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        exact: true,
        Component: Dashboard,
        secured: true
    },
    {
        path: '/falls/approved',
        name: 'Approved Falls',
        exact: true,
        Component: FallsApproved,
        secured: true
    },
    {
        path: '/falls/rejected',
        name: 'Rejected Falls',
        exact: true,
        Component: FallsRejected,
        secured: true
    },
    {
        path: '/logins',
        name: 'Logins',
        exact: true,
        Component: Logins,
        secured: true
    },
    {
        path: '*',
        name: 'NoMatch',
        exact: false,
        Component: NoMatch,
        secured: false
    }
];

/**
 * Map over and render routes + handle navigation.
 */
function Routing() {
    return (
        <Routes>
            {routes.map(({ path, Component, secured }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <RequireAuth secured={secured} redirectTo={path}>
                            <Component />
                        </RequireAuth>
                    }
                />
            ))}
        </Routes>
    );
}

export default Routing;
