import { useContext, useEffect, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { FallContext } from '../../../contexts/FallContext';
import { SidebarContext } from '../../../contexts/SidebarContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Circle from '../../common/Circle/Circle';
import './Sidebar.scss';

interface SidebarProps {
    routes: RouteConfig[];
}

interface SidebarConfig {
    header: string | null; // A category label
    icon: string; // Bootstrap-icon string
    routeNames: string[]; // Route names (case-sensitive)
}

function Sidebar({ routes }: SidebarProps) {
    const sidebarItems: SidebarConfig[] = [
        {
            header: null,
            icon: 'bi-house-door',
            routeNames: ['Dashboard']
        },
        {
            header: 'History',
            icon: 'bi-clock-history',
            routeNames: ['Approved Falls', 'Rejected Falls']
        },
        {
            header: null,
            icon: 'bi-stopwatch',
            routeNames: ['Logins']
        }
    ];

    const { isDesktopDevice, isMobileDevice, isTabletDevice } = useMediaQuery();
    const { isSidebarVisible, getSidebarWidth } = useContext(SidebarContext);

    /**
     * Returns sidebar JSX for Desktop devices
     */
    function renderForDesktop() {
        return (
            <nav className="sidenav h-100 pt-2" style={{ width: getSidebarWidth() }}>
                {RenderSidebarItems(sidebarItems, routes, {
                    expandChildren: true,
                    padLeftChildren: true,
                    showDashboardNotification: true
                })}
            </nav>
        );
    }

    /**
     * Returns sidebar JSX for Tablet devices
     */
    function renderForTablet() {
        return (
            <nav className="sidenav h-100 pt-2" style={{ width: getSidebarWidth() }}>
                {RenderSidebarItems(sidebarItems, routes, {
                    showDashboardNotification: true,
                    showIcons: true
                })}
            </nav>
        );
    }

    /**
     * Returns sidebar JSX for Mobile devices
     */
    function renderForMobile() {
        return (
            <nav className={`${isSidebarVisible ? 'sidenav w-100' : 'd-none'}`}>
                {RenderSidebarItems(sidebarItems, routes, {
                    expandChildren: true,
                    padLeftChildren: true
                })}
            </nav>
        );
    }

    if (isDesktopDevice()) return renderForDesktop();
    else if (isTabletDevice()) return renderForTablet();
    else if (isMobileDevice()) return renderForMobile();
    return null;
}

/**
 * Returns JSX for items in the sidebar
 * @param sidebarConfigs Array of SidebarConfigs
 * @param routes Array of Routes
 * @param param Object
 * @param param.expandChildren Children items are expanded on init
 * @param param.padLeftChildren Children items are padded-left
 * @param param.showIcons Show icons
 * @param param.showDashboardNotification Show notification bubble
 */
function RenderSidebarItems(
    sidebarConfigs: SidebarConfig[],
    routes: RouteConfig[],
    {
        expandChildren = false,
        padLeftChildren = false,
        showDashboardNotification = false,
        showIcons = false
    }: {
        expandChildren?: boolean;
        padLeftChildren?: boolean;
        showDashboardNotification?: boolean;
        showIcons?: boolean;
    }
) {
    return sidebarConfigs.map((sidebarConfig) => {
        const hasHeader = sidebarConfig.header !== null;

        // Creates a <CustomLink> for each valid route and store it in an array
        const customLinks: JSX.Element[] = [];
        sidebarConfig.routeNames.forEach((routeName) => {
            const existingRoute = routes.find(({ name }) => name === routeName);
            if (existingRoute) {
                const { path, name } = existingRoute;
                customLinks.push(
                    <CustomLink
                        key={path}
                        path={path}
                        label={name}
                        icon={showIcons && !hasHeader ? sidebarConfig.icon : ''}
                        showNotification={showDashboardNotification}
                        padLeft={padLeftChildren && hasHeader}
                    />
                );
            }
        });

        /* Creates a dropdown for the CustomLinks array if it has
        a parent header and at least 2 routes */
        if (hasHeader && sidebarConfig.header && customLinks.length > 1) {
            return (
                <SidebarItemDropdown
                    key={sidebarConfig.header}
                    label={sidebarConfig.header}
                    icon={showIcons ? sidebarConfig.icon : ''}
                    content={customLinks}
                    startExpanded={expandChildren}
                />
            );
        }

        return customLinks;
    });
}

/**
 * A custom Link component that wraps around a SidebarItem that:
 * - Boldens label text of selected SidebarItem
 * - Shows a notification circle if the route is Dashboard
 * @param param Object
 * @param param.path URL path to direct to
 * @param param.label Text label
 * @param param.icon Bootstrap-icon string
 * @param param.padLeft Adds padding to the left
 * @param param.showNotification Show notification bubble
 */
function CustomLink({
    path,
    label,
    icon,
    padLeft,
    showNotification = true
}: {
    path: string;
    label: string;
    icon?: string;
    padLeft?: boolean;
    showNotification?: boolean;
}) {
    // Ensures the full URL route path matches the current URL
    const resolvedPath = useResolvedPath(path);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    const { hideSidebar } = useContext(SidebarContext);
    const { fallsToActionCount } = useContext(FallContext);

    // If Dashboard route, show notification circle if enabled
    const isDashboardRoute = label === 'Dashboard';

    return (
        <Link className={`${isActive ? 'active' : ''}`} to={path}>
            <SidebarItem
                label={label}
                icon={icon}
                padLeft={padLeft}
                showNotification={showNotification && isDashboardRoute}
                notificationText={fallsToActionCount.review + fallsToActionCount.confirm}
                onClick={hideSidebar}
            />
        </Link>
    );
}

/**
 * An item in the sidebar
 * @param param Object
 * @param param.label Text label
 * @param param.icon Bootstrap-icon string
 * @param param.padLeft Adds padding to the left
 * @param param.showNotification Show notification bubble
 * @param param.notificationText Text inside notification bubble
 * @param param.onClick Function triggering on click
 */
function SidebarItem({
    label,
    icon,
    padLeft,
    showNotification,
    notificationText,
    onClick
}: {
    label: string;
    icon?: string;
    padLeft?: boolean;
    showNotification?: boolean;
    notificationText?: number | string;
    onClick?: () => void;
}) {
    const { isTabletDevice } = useMediaQuery();
    return (
        <div
            className={`sidenav__header d-flex flex-row align-items-center
            ${padLeft ? 'pl-3' : ''} onClick={() => (isMobileDevice() ? hideSidebar() : null)}
            ${isTabletDevice() ? 'flex-column-reverse justify-content-center text-center' : ''}`}
            onClick={onClick}
        >
            {label}
            <span className="d-flex justify-content-center">
                <span className={`sidenav__icon bi ${icon}`} />
                {showNotification ? (
                    <Circle
                        className={`bg-danger ${icon ? 'position-absolute ml-4' : 'ml-3'}`}
                        text={notificationText}
                    />
                ) : null}
            </span>
        </div>
    );
}

/**
 * A sidebar dropdown component that shows/hides content when clicked
 * @param param Object
 * @param param.label Text label
 * @param param.content JSX Elements to show/hide when clicked
 * @param param.icon Bootstrap-icon string
 * @param param.startExpanded Show children on init
 */
function SidebarItemDropdown({
    label,
    content,
    icon,
    startExpanded = false
}: {
    label: string;
    content: JSX.Element[];
    icon?: string;
    startExpanded?: boolean;
}) {
    const [expanded, setExpanded] = useState(startExpanded);
    useEffect(() => setExpanded(startExpanded), [startExpanded]);

    const handleOnClick = () => setExpanded(!expanded);

    return (
        <span className="d-flex flex-column" role="button">
            <span onClick={handleOnClick}>
                <SidebarItem label={label} icon={icon} />
            </span>
            {expanded ? content : null}
        </span>
    );
}

export default Sidebar;
